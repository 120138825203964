import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { graphql } from 'gatsby';
import { Grid, Typography } from '@material-ui/core';
import FlipMove from 'react-flip-move';
import qs from 'querystringify';
import { Alert } from '@sporkbytes/material-ui-kit-react';

import Container from 'components/Container';
import Filters from 'components/Filters';
import PageWrapper from 'components/PageWrapper';
import PartnerCard from 'components/PartnerCard';

import Hero from 'storyblok/components/Hero';

const defaultSort = 'Featured First';

function deepClean(object) {
	function trimAndReplaceEmptyStrings(key, value) {
		if (typeof value === 'string') {
			value = value.trim();
		}

		if (value === '') {
			return null;
		}

		return value;
	}

	object = JSON.stringify(object, trimAndReplaceEmptyStrings);

	return JSON.parse(object);
}

function shuffle(array) {
	return array.slice().sort(() => Math.random() * 2 - 1);
}

function filterPartners(partners, { location, mealType, tags, sort }) {
	if (tags.length > 0) {
		partners = partners.filter(partner =>
			tags.every(
				tag =>
					!!partner.PartnerTags.find(
						partnerTag => partnerTag.name === tag
					)
			)
		);
	}

	if (location) {
		partners = partners.filter(partner =>
			partner.SporkLocations.some(
				sporkLocation => sporkLocation.slug === location
			)
		);
	}

	if (mealType) {
		partners = partners.filter(partner =>
			partner.MealTypes.some(
				partnerMealType => partnerMealType.name === mealType
			)
		);
	}

	if (sort) {
		switch (sort) {
			case 'Alphabetically':
				partners = partners
					.slice()
					.sort((partnerA, partnerB) =>
						partnerA.name.localeCompare(partnerB.name)
					);
				break;

			case 'New First':
				const newPartners = partners.filter(
					partner => partner.isNewPartner
				);
				const oldPartners = partners.filter(
					partner => !partner.isNewPartner
				);

				partners = [...shuffle(newPartners), ...shuffle(oldPartners)];
				break;

			case 'Shuffled':
				partners = shuffle(partners);
				break;

			case defaultSort:
			default:
				const featuredPartners = partners.filter(
					partner => partner.isFeaturedPartner
				);
				const otherPartners = partners.filter(
					partner => !partner.isFeaturedPartner
				);

				partners = [
					...shuffle(featuredPartners),
					...shuffle(otherPartners),
				];
				break;
		}
	}

	return partners;
}

const TheRestaurants = ({ data, location: { search } }) => {
	const [filteredPartners, setFilteredPartners] = useState(
		data.allPartner.nodes
	);

	useEffect(() => {
		setFilteredPartners(
			filterPartners(data.allPartner.nodes, getFiltersFromURL())
		);
	}, [search]);

	function getFiltersFromURL() {
		const { location, mealType, tags, sort } = qs.parse(decodeURI(search));

		return {
			location,
			mealType,
			tags: tags ? tags.split(',') : [],
			sort: sort || defaultSort,
		};
	}

	const setFiltersInUrl = ({ location, mealType, tags, sort }) => {
		const cleanedFilters = deepClean({
			location: location || undefined,
			mealType: mealType || undefined,
			tags: tags.length > 0 ? tags.join(',') : undefined,
			sort: sort || defaultSort,
		});

		navigate(`?${encodeURI(qs.stringify(cleanedFilters))}`);
	};

	const NoPartnersFound = () => (
		<Grid item xs={12}>
			<Alert type="info">
				<Typography>
					No restaurants found with these filters. Please try a
					different combination of filters.
				</Typography>
			</Alert>
		</Grid>
	);

	const pageTitle = 'The Restaurants';

	return (
		<PageWrapper
			title={pageTitle}
			description="The best Portland restaurants at your fingertips."
			ogImage={{
				alt: data.site.siteMetadata.title,
				src: `${data.site.siteMetadata.url}${data.headerImage.childImageSharp.fixed.src}`,
			}}
			postContent={
				<>
					<Hero
						blok={{
							backgroundImage:
								data.headerImage.childImageSharp.fluid,
							headline: pageTitle,
							height: 'short',
						}}
					/>
					<Container>
						{/* Relative position keeps FlipMove from throwing a warning and setting an inline style of its own */}
						<Grid
							container
							spacing={2}
							style={{ position: 'relative' }}
						>
							<Grid item xs={12}>
								<Filters
									filters={getFiltersFromURL()}
									onChange={setFiltersInUrl}
									allMealTypes={data.allMealType.nodes.map(
										mealType => mealType.name
									)}
									allPartnerTags={data.allPartnerTag.nodes.map(
										partnerTag => partnerTag.name
									)}
									allSporkLocations={
										data.allSporkLocation.nodes
									}
								/>
							</Grid>
							<FlipMove typeName={null} duration={300}>
								{filteredPartners.map(partner => (
									<Grid
										item
										key={partner.id}
										xs={12}
										sm={6}
										md={4}
										lg={3}
									>
										<PartnerCard
											partner={partner}
											showFeaturedRibbon={true}
											showSporkLocations={
												data.allSporkLocation.nodes
													.length > 1
											}
										/>
									</Grid>
								))}
							</FlipMove>
							{filteredPartners.length === 0 && (
								<NoPartnersFound />
							)}
						</Grid>
					</Container>
				</>
			}
		/>
	);
};

export const query = graphql`
	query GetPartnersForTheRestaurants {
		allPartnerTag {
			nodes {
				id
				name
			}
		}
		allMealType {
			nodes {
				id
				name
			}
		}
		allSporkLocation {
			nodes {
				id
				name
				slug
			}
		}
		allPartner {
			nodes {
				id
				name
				description
				isFeaturedPartner
				isNewPartner
				landingPageSlug
				localPhoto {
					childImageSharp {
						fluid(maxWidth: 400) {
							...GatsbyImageSharpFluid_tracedSVG
						}
					}
				}
				MealTypes {
					id
					name
				}
				PartnerTags {
					id
					name
				}
				SporkLocations {
					id
					name
					slug
				}
			}
		}
		site {
			siteMetadata {
				title
				url
			}
		}
		headerImage: file(relativePath: { eq: "the-restaurants-hero.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1500) {
					...GatsbyImageSharpFluid_tracedSVG
				}
				fixed(height: 630, width: 1200) {
					src
				}
			}
		}
	}
`;

export default TheRestaurants;
