import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {
	Button,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Grid,
	NoSsr,
	Typography,
} from '@material-ui/core';
import {
	CancelPresentation as ResetIcon,
	ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { MultiSelect, SingleSelect } from '@sporkbytes/material-ui-kit-react';

import { sendEvent } from 'services/analytics';

const useStyles = makeStyles(theme => ({
	expansionPanel: {
		borderTop: `${theme.sizes.topBorderWidth}px solid ${theme.palette.brand.gold}`,
		marginTop: theme.sizes.gutter,
	},
	filterContainer: {
		background: theme.palette.common.white,
		boxShadow: theme.boxShadow,
		padding: theme.spacing(0, 2, 2),
		[theme.breakpoints.up('sm')]: {
			borderTop: `${theme.sizes.topBorderWidth}px solid ${theme.palette.brand.gold}`,
			margin: theme.spacing(-2, 2, 4),
			paddingTop: theme.spacing(2),
		},
		[theme.breakpoints.up('md')]: {
			margin: theme.spacing(-4, 4, 8),
			padding: theme.spacing(3),
		},
	},
	filterHeading: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block',
			marginTop: theme.spacing(2), // fixes vertical alignment with Select elements since they have internal margin for floating labels
			textAlign: 'center',
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			marginRight: theme.sizes.gutter,
			width: 'auto',
		},
	},
	resetButton: {
		color: theme.palette.grey[500],
		fontWeight: 700,
		marginTop: theme.sizes.gutter,
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			margin: `${theme.spacing(2)}px auto 0`, // top margin fixes vertical alignment with Select elements since they have internal margin for floating labels
		},
	},
	resetButtonContainer: {
		flexGrow: 1,
	},
	selectContainer: {
		paddingRight: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '50%',
		},
		[theme.breakpoints.up('md')]: {
			width: ({ showLocationFilter }) =>
				showLocationFilter ? '18%' : '24%',
		},
		[theme.breakpoints.up('lg')]: {
			width: ({ showLocationFilter }) =>
				showLocationFilter ? '20%' : '25%',
		},
	},
}));

const sortOptions = [
	'Featured First',
	'Alphabetically',
	'New First',
	'Shuffled',
];

const Filters = ({
	filters,
	allMealTypes,
	allPartnerTags,
	allSporkLocations,
	width,
	onChange,
}) => {
	const showLocationFilter = allSporkLocations.length > 1;
	const classes = useStyles({ showLocationFilter });
	const SelectElements = (
		<Grid container spacing={2} alignItems="center">
			<Typography
				variant="h3"
				component="h1"
				className={classes.filterHeading}
			>
				Filter by:
			</Typography>
			{showLocationFilter ? (
				<div className={classes.selectContainer}>
					<SingleSelect
						label="Location"
						options={allSporkLocations}
						getOptionKey={({ id }) => id}
						getOptionLabel={({ name }) => name}
						getOptionValue={({ slug }) => slug}
						value={filters.location}
						onChange={location => {
							sendEvent({
								action: 'Set Spork Location',
								label: location || 'All',
							});

							onChange({ ...filters, location });
						}}
						emptyOptionLabel={'All'}
					/>
				</div>
			) : null}
			<div className={classes.selectContainer}>
				<SingleSelect
					label="Meal"
					options={allMealTypes}
					value={filters.mealType}
					onChange={mealType => {
						sendEvent({
							action: 'Set Meal Type',
							label: mealType || 'All',
						});

						onChange({ ...filters, mealType });
					}}
					emptyOptionLabel={'All'}
				/>
			</div>
			<div className={classes.selectContainer}>
				<MultiSelect
					label="Tag"
					options={allPartnerTags}
					value={filters.tags}
					onChange={tags => {
						sendEvent({
							action: 'Set Tags',
							label: tags.length > 0 ? tags.join(', ') : 'Reset',
						});

						onChange({ ...filters, tags });
					}}
				/>
			</div>
			<div className={classes.selectContainer}>
				<SingleSelect
					label="Sort"
					options={sortOptions}
					value={filters.sort}
					onChange={sort => {
						sendEvent({
							action: 'Set Sort',
							label: sort,
						});

						onChange({ ...filters, sort });
					}}
				/>
			</div>
			<div className={classes.resetButtonContainer}>
				<Button
					onClick={() => {
						sendEvent({
							action: 'Reset',
						});

						onChange({
							mealType: undefined,
							sort: undefined,
							tags: [],
						});
					}}
					text="Reset"
					title="Reset filters"
					className={classes.resetButton}
					endIcon={<ResetIcon />}
				>
					Reset
				</Button>
			</div>
		</Grid>
	);

	if (isWidthUp('sm', width)) {
		return <div className={classes.filterContainer}>{SelectElements}</div>;
	}

	return (
		<NoSsr>
			<ExpansionPanel square={true} className={classes.expansionPanel}>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography component="h1" variant="h5">
						Filter by:
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.filterContainer}>
					{SelectElements}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</NoSsr>
	);
};

export default withWidth()(Filters);
